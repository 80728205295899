import React, { useState,useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,  
  CardBody
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import "react-rangeslider/lib/index.css"
import "flatpickr/dist/themes/material_blue.css";
import { getCCXTAllExchanges, getCCXTUserBalance, getCCXTUserExchangePairs, getCCXTUserExchanges } from "helpers/ccxt_helper";
import { Link } from "react-router-dom";
const Wallet = () => {
  document.title = "Spot Trade - Wallet";
  firebase.auth();
  const firestoreDb = firebase.firestore();
  const currentUser = JSON.parse(localStorage.getItem('user'));
  let uid = currentUser.uid;
  
  const [formLoader1, setFormLoader1] = useState(true);
  const [NOExchange,setNOExchange] = useState(false);
   const handleError = (err) => {
    if (err && err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message, { toastId: err.response.data.message });
    } else if (err.message) {
      toast.error(err.message, { toastId: err.message });
    } else {
      toast.error(err, { toastId: err });
    }
  }
  const [SelectExchange,setSelectExchange] = useState(false)
  const [ccxtAllExchanges, setCCXTAllExchanges] = useState([]);  
  //Get Exchanges list
  const getCCXTAllExchangesList = async () => {
    try {      
      await getCCXTAllExchanges().then((res) => {
        setCCXTAllExchanges(res.data.response.exchanges);        
      }).catch(e => {
        throw e
      })
    } catch (e) {      
      handleError(e);
    }
  }

  const [ccxtUserExchanges, setCCXTUserExchanges] = useState([]);  
  //Get Exchanges list
  const getCCXTUserExchangesList = async () => {
    try {      
      await setupCCXTUserExchanges();
      setFormLoader1(false);
    } catch (e) {    
      setFormLoader1(false);
    }
  }

  const setupCCXTUserExchanges = async () => {
    await getCCXTUserExchanges().then( async (res) => {
      let exchanges = res.data.response.exchanges;
      if (exchanges && exchanges.length === 0){
        setNOExchange(true)
      }
      if (exchanges && exchanges.length) {
        let user_exchanges = await Promise.all(exchanges.map( async (exchange) => {
          exchange.details = ccxtAllExchanges.find(item => item.id == exchange.exchange_id);          
          await getCCXTUserExchangePairs(exchange.exchange_id).then((res) => {
            let markets = res.data.response.data;
            let exchange_pairs = [];
            if (markets && markets.length) {
              exchange_pairs = markets
            }            
            exchange.pairs = exchange_pairs;
          }).catch(e => {
            throw e
          });     
          return exchange;
        }));    
        let list = [];
        user_exchanges.map((exchange, index) => {
            if (exchange.pairs.length !== 0){
              list.push(exchange)
            }
        })
        setCCXTUserExchanges(list);
      }
    }).catch(e => {
      throw e
    });
  }

  const [ccxtUserExchangeCurrencies, setCCXTUserExchangeCurrencies] = useState([]);  
  const getUserExchangeCurrencies = async (exchange_id) => {
    await getCCXTUserExchangePairs(exchange_id).then((res) => {
      let markets = res.data.response.data;      
      let all_currencies = [];
      let exchange_currencies = [];      
      if (markets && markets.length) {        
        markets.map(market => {
          market=market.data;
          all_currencies.push(market.base);
          all_currencies.push(market.quote);          
        });        
        exchange_currencies = all_currencies.filter((item, index) => all_currencies.indexOf(item) === index);         
        setCCXTUserExchangeCurrencies(exchange_currencies);
      } else {        
        setCCXTUserExchangeCurrencies(exchange_currencies);
      }      
    }).catch(e => {
      throw e
    });
  }

  const [ccxtUserBalances, setCCXTUserBalances] = useState({});  
  const getCCXTUserBalances = async (exchange_id) => {
    await getCCXTUserBalance(exchange_id).then((res) => {
      let balance = res.data.response.data;            
      if (balance) {                        
        setCCXTUserBalances(balance);
      }      
    }).catch(e => {
      throw e
    });
  }

  const handleChange = async (e) => {
    if (e.target.value !== 'SelectExchange'){
      setSelectExchange(false)
      await getBalanceDetails(e.target.value);
    }else{
      setSelectExchange(true)
    }
  }

  const getBalanceDetails = async (exchange_id) => {
    try {
      setFormLoader1(true);
      setCCXTUserExchangeCurrencies([]);
      setCCXTUserBalances([]);
      await getCCXTUserBalances(exchange_id);
      await getUserExchangeCurrencies(exchange_id);    
      setFormLoader1(false);
    } catch (e) {
      setFormLoader1(false);
      handleError(e);
    }
  }

  useEffect(() => {    
    setSelectExchange(true)
    getCCXTAllExchangesList();    
  }, []);
  
  useEffect(() => {
    if (ccxtAllExchanges.length) {      
      getCCXTUserExchangesList();         
    }    
  }, [ccxtAllExchanges]);

  useEffect(() => {
    if (ccxtUserExchanges.length) {
      if (document.getElementById('select_exchange') && document.getElementById('select_exchange').value !== 'SelectExchange')  {
        setSelectExchange(false)
      let exchange_id = document.getElementById('select_exchange').value;
      getBalanceDetails(exchange_id);
      }else{
        setFormLoader1(false);
        setSelectExchange(true)
      }
    }    
  }, [ccxtUserExchanges]);
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="DeriSnap" breadcrumbItem="Wallet" />

          <Row>
            {!NOExchange ? 
            ccxtUserExchanges.length !== 0 ? (       
            <Col xl={12}>
              <Card className="border box_4">
                <CardBody>                     
                  <Row>                              
                    <Col md={4}>
                      <select id="select_exchange" hidden={formLoader1 ? true : false} className="form-select" defaultValue="SelectExchange"
                        onChange={(e) => {                  
                          handleChange(e);
                        }}
                      >
                        {/* {ccxtUserExchanges.length !== 0 ? ( */}
                          {/* <> */}
                      {ccxtUserExchanges.map((exchange,index) => (
                        <React.Fragment key={index}>
                          <option value={exchange.exchange_id} > {exchange.details.name} </option>
                          {/* {index === ccxtUserExchanges.length -1 ? (<option value="SelectExchange">Select Exchange</option>): ""}  */}
                        </React.Fragment>
                      ))}
                      {/* </>) */}
                      {/* // :   (<option value="SelectExchange">Select Exchange</option>)  }                   */}
                    </select>
                    </Col> 
                    <Col md={4} className="fs-4 mt-2">
                   { ccxtUserBalances.total_USDT ? <p className="mb-0">
                      Total USDT :&nbsp; <b>{Number(ccxtUserBalances.total_USDT).toFixed(4)}</b>
                    </p> : null
                    }
                    </Col> 
                </Row>
                </CardBody>
              </Card>
            </Col>
             ) : '' :''} 
            <Col xl={12}>
            <Card style={{minHeight:'92%'}} className="box_4">
                <CardBody>                  
                {/* {SelectExchange  ? 
                  <Col xl={12}>
                    <div className="p-4 border text-center">
                      <h6 className="text-muted">Please Select Exchange</h6>    
                    </div>
                    </Col>    
                    : formLoader1 ?
                  <Row>
                   <Col xl={12}>
                    <div className="p-4 border text-center">
                      <h6 className="text-muted">Loading...</h6>    
                    </div>
                  </Col>
                  </Row>
                : */}
                <Row>
                  
                  { 
                  NOExchange ? (
                    <>
                    <Col xl={12}>
                    <div className="p-4 border text-center">
                      <h6 className="text-muted">No Exchange Added. Please Go to <Link to="/ccxt/settings">Settings.</Link> </h6>    
                    </div>
                    </Col> 
                    </>
                  ):
                  (ccxtUserExchangeCurrencies && ccxtUserExchangeCurrencies.length ?
                    ccxtUserExchangeCurrencies.map((currency, index) => {
                      return (
                        <Col key={index} lg="3">
                        <div className="border p-3 rounded mt-4">
                          <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-info text-white font-size-18">
                                <i className="mdi mdi-circle" />
                              </span>
                            </div>
                            <h5 className="font-size-14 mb-0">{currency}</h5>
                          </div>

                          <Row>                            
                            <div className="col-lg-6">
                              <div className="mt-3">
                                <p>Wallet</p>
                                <h4 className={ccxtUserBalances && ccxtUserBalances[currency] && ccxtUserBalances[currency]['total'] > 0 ? "text-green" : "text-red"}>{ccxtUserBalances && ccxtUserBalances[currency] && ccxtUserBalances[currency]['total'] ? Number(ccxtUserBalances[currency]['total']).toFixed(5) : '0.00'}</h4>
                              </div>
                            </div>
                            <div className="col-lg-6 align-self-end">
                              <div className="float-end mt-3">
                                <p>Available</p>
                                <h4 className={ccxtUserBalances && ccxtUserBalances[currency] &&  ccxtUserBalances[currency]['free'] > 0 ? "text-green" : "text-red"}>{ccxtUserBalances && ccxtUserBalances[currency] && ccxtUserBalances[currency]['free'] ? Number(ccxtUserBalances[currency]['free']).toFixed(5) : '0.00'}</h4>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Col>
                      )                      
                    })
                    :
                    formLoader1 ?
                      <Row>
                      <Col xl={12}>
                        <div className="p-4 border text-center">
                          <h6 className="text-muted">Loading...</h6>    
                        </div>
                      </Col>
                      </Row>
                    :
                      <Col xl={12}>
                      <div className="p-4 border text-center">
                        <h6 className="text-muted">No Pairs Found. Please Go to <Link to="/ccxt/settings?pairs">Pairs Settings.</Link></h6>    
                      </div>
                      </Col>
                  )
                }
                  </Row>              
             {/* } */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      </React.Fragment>
    
  )
}

export default Wallet
