
import React, { useEffect, useRef, useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardHeader,
    CardText,
    CardTitle, Spinner, Alert, Table, Tooltip, Badge,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Popover,
    PopoverHeader,
    PopoverBody,
    Button
} from "reactstrap";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

let tvScriptLoadingPromise;
const TradingView = (data) => {
    const onLoadScriptRef = useRef();
    const [loadGrapgh,setloadGrapgh] = useState(true)
    const handle = useFullScreenHandle();
    const [fullsreen,setfullscreen] = useState(false)
    useEffect(
      () => {
        setloadGrapgh(true)
        onLoadScriptRef.current = createWidget;
  
        if (!tvScriptLoadingPromise) {
          tvScriptLoadingPromise = new Promise((resolve) => {
            const script = document.createElement('script');
            script.id = 'tradingview-widget-loading-script';
            script.src = 'https://s3.tradingview.com/tv.js';
            script.type = 'text/javascript';
            script.onload = resolve;
  
            document.head.appendChild(script);
          });
        }
  
        tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());
  
        return () => onLoadScriptRef.current = null;
  
        function createWidget() {
            // var rowSymbole = window.location.search.substring(1)
            var rowSymbole = data.data.toString()
            var spli =  rowSymbole ? rowSymbole.split(",") : "";
            var  symbol = spli[0].replace('/','')
            var studies = spli[1]
            var interval = '15m'
            console.log(symbol, 'symbol __________')
            if (symbol == 'delta:BTCUSDT'){
              symbol = 'BINANCE:BTCUSDT'
            }
            if(studies=='sma'){
              if (spli[3] == '' || spli[4] == '' ){
                setloadGrapgh(false)
              }else{
                setloadGrapgh(true)
              }
              var min = spli[2]
              var min2 = min?min.replace('m',''):''
              min2 = min2.replace('w','W')
              min2 = min2.replace('d','D')
              var hours = min2.split('h').length > 1 ? min2.split('h') : '' 
              min2 = hours ? hours[0]*60 : min2
              studies = [{id : "STD;SMA",inputs: {in_0:spli[3],in_5: min2.toString()}},{id : "STD;SMA",inputs: {in_0:spli[4],in_5: min2.toString()}}]
              interval = spli[2]
            }else if(studies=='ema'){
              var min = spli[5]
              var min2 = min?min.replace('m',''):''
              min2 = min2.replace('w','W')
              min2 = min2.replace('d','D')
              var hours = min2.split('h').length > 1 ? min2.split('h') : '' 
              min2 = hours ? hours[0]*60 : min2
              if (spli[6] == '' || spli[7] == '' ){
                setloadGrapgh(false)
              }else{
                setloadGrapgh(true)
              }
              studies = [{id:"STD;EMA",inputs: {in_0 : spli[6],in_5: min2.toString()},override: {
                "Plot.visible": true,
                "Plot.linewidth": 10,
                "Plot.plottype": "line",
                "Plot.color": "rgb(250, 50, 50)"
              }},{id:"STD;EMA",inputs: {in_0 : spli[7],in_5: min2.toString()},override: {
                "visible": true,
                "linewidth": 1,
                "plottype": "line",
                "color": "rgb(250, 5, 5)"
              }}]
              interval = spli[5]
            }else if(studies=='macd'){
              var min = spli[8]
              var min2 = min?min.replace('m',''):''
              min2 = min2.replace('w','W')
              min2 = min2.replace('d','D')
              var hours = min2.split('h').length > 1 ? min2.split('h') : '' 
              min2 = hours ? hours[0]*60 : min2
              if (spli[9] == '' || spli[11] == '' || spli[10] == '' ){
                setloadGrapgh(false)
              }else{
                setloadGrapgh(true)
              }
              studies = [{id : "STD;MACD",inputs: {in_0 : spli[9],in_1 : spli[10],in_3 : spli[11],in_12: min2.toString()}}]
              interval = spli[8]
            }else if(studies=='supertrend'){
              var min = spli[12]
              var min2 = min?min.replace('m',''):''
              min2 = min2.replace('w','W')
              min2 = min2.replace('d','D')
              var hours = min2.split('h').length > 1 ? min2.split('h') : '' 
              min2 = hours ? hours[0]*60 : min2
              if (spli[13] == '' || spli[14] == ''){
                setloadGrapgh(false)
              }else{
                setloadGrapgh(true)
              }
              studies = [{id: "STD;Supertrend",inputs: {in_0: spli[13], in_1: spli[14],in_2: min2.toString()}}]
              interval = spli[12]

            }
            if (interval == '1m'){
              interval = '1'
            }
            if (interval == '5m'){
              interval = '5'
            }
            if (interval == '15m'){
              interval = '15'
            }
            if (interval == '30m'){
              interval = '30'
            }
            if (interval == '1h'){
              interval = '60'
            }
            if (interval == '2h'){
              interval = '120'
            }
            if (interval == '4h'){
              interval = '240'
            }
            if (interval == '1d'){
              interval = 'D'
            }
            if (interval == '1w'){
              interval = 'W'
            }
            var length = spli[2]
          if (document.getElementById('tradingview_2a46a') && 'TradingView' in window) {
            new window.TradingView.widget(
              {
              autosize: true,
              symbol: symbol,
              // session: '24x7',
              interval: String(interval),
              timezone: 'Asia/Kolkata',
              theme: "light",
              style: "1",
              locale: "in",
              toolbar_bg: "#c71619",
              enable_publishing: false,
              withdateranges: true,
              // range: "YTD",
              hide_side_toolbar: false,
              allow_symbol_change: true,
              details: true,
              calendar: true,
              // interval: "15",
              studies: studies,
              container_id: "tradingview_2a46a",
              hidevolume: true,
              disabled_features: ["volume_force_overlay"],
              overrides: {
                "mainSeriesProperties.candleStyle.upColor": "rgba(0, 255, 0, 0)", // green color for up candles
                "mainSeriesProperties.candleStyle.downColor": "rgba(255, 0, 0, 0)", // red color for down candles
                "mainSeriesProperties.candleStyle.borderUpColor": "#84d9c9", // border color for up candles
                "mainSeriesProperties.candleStyle.borderDownColor": "#f07a83",
              }
            });
          }
        }
      },
      [data.data,loadGrapgh]
    );
  //"STD;Average_True_Range","STD;RSI","STD;Smoothed%1Moving%1Average",
    return (
      <React.Fragment>
      <div className="page-content" style={{padding:"0px",margin:"1px"}}>
  
          <Container fluid style={{padding:"0px",margin:"1px"}}>
          <Row style={{width:"1115px",float:"left"}}> 
          <h4 className="mb-0 font-size-18">Trading View { loadGrapgh ? (<i className="bx bx-fullscreen float-end" style={{position:"relative",top:"33px",right:"40px", zIndex: "9",color:"#7F7F7F"}} onClick={handle.enter} ></i>):""}</h4>
                          <Col lg="12" style={{"height":"500px"}}>
                            {loadGrapgh ? 
      <FullScreen handle={handle}>
      <div className='tradingview-widget-container' style={handle.active ? {"height":"100%"} : {"height":"500px"}}>
        <div id='tradingview_2a46a'  style={handle.active ? {"height":"100%"} : {"height":"500px"}}/>
        <div className="tradingview-widget-copyright" style={handle.active ? {"height":"100%"} : {"height":"500px"}}>
          {/* <a href="https://in.tradingview.com/symbols/BTCUSDT/?exchange=DELTA" >
            <span className="blue-text">BTCUSDT chart</span></a> */}
        </div>
      </div>
      </FullScreen>
      :  <div className="p-4 text-center">
      <h6 className="text-muted">Loading chart, please enter values to view the Trading chart</h6>
    </div>}
      </Col></Row>
      </Container>
      </div>
      </React.Fragment>
    );
}

export default TradingView