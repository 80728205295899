import api from './api_helper';

// Get CCXT All Exchanges
const getCCXTAllExchanges = async () => {
  return new Promise(async (resolve, reject) => {
    await api({ contentType: true, auth: true }).get('/ccxt/all-exchanges').then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}


// Get CCXT User Exchanges
const getCCXTUserExchanges = async () => {
  return new Promise(async (resolve, reject) => {
    await api({ contentType: true, auth: true }).get('/ccxt/config').then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}


// Update CCXT Config
const updateCCXTExchangeConfig = async (bodyParams) => {
  return new Promise(async (resolve, reject) => {
    await api({ contentType: true, auth: true }).post('/ccxt/config', bodyParams).then(async (res) => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

// Update CCXT Config
const updateExchangeSl = async (bodyParams) => {
  return new Promise(async (resolve, reject) => {
    await api({ contentType: true, auth: true }).post('/ccxt/updateExchangeSl', bodyParams).then(async (res) => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

// Update CCXT Config
const updateCCXTExchangeStatus = async (bodyParams) => {
  return new Promise(async (resolve, reject) => {
    await api({ contentType: true, auth: true }).post('/ccxt/exchange-status', bodyParams).then(async (res) => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

// Get CCXT Exchange Markets/Pairs
const getCCXTExchangeMarkets = async (exchange_id) => {
  return new Promise(async (resolve, reject) => {
    await api({ contentType: true, auth: true }).get('/ccxt/all-markets/'+exchange_id).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

const getUserExchangePairs =async(exchange_id)=>{
  return new Promise(async (resolve, reject) => {
    await api({ contentType: true, auth: true }).get('/ccxt/user-exchange-pair/'+exchange_id).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
const getAllUserExchangePairs =async(exchange_id)=>{
  return new Promise(async (resolve, reject) => {
    await api({ contentType: true, auth: true }).get('/ccxt/user-exchange-pair').then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
// Update CCXT Exchange Pair
const updateCCXTExchangePair = async (bodyParams) => {
  return new Promise(async (resolve, reject) => {
    await api({ contentType: true, auth: true }).post('/ccxt/exchange-pair', bodyParams).then(async (res) => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

// Get CCXT Exchange Markets/Pairs
const getCCXTUserExchangePairs = async (exchange_id) => {
  return new Promise(async (resolve, reject) => {
    await api({ contentType: true, auth: true }).get('/ccxt/exchange-pair/'+exchange_id).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

// Get CCXT User Balance
const getCCXTUserBalance = async (exchange_id) => {
  return new Promise(async (resolve, reject) => {
    await api({ contentType: true, auth: true }).get('/ccxt/balances/'+exchange_id).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

export {
  getCCXTAllExchanges,
  getCCXTUserExchanges,
  updateCCXTExchangeConfig,
  updateCCXTExchangeStatus,
  getCCXTExchangeMarkets,
  updateCCXTExchangePair,
  getCCXTUserExchangePairs,
  getCCXTUserBalance,getUserExchangePairs,getAllUserExchangePairs,updateExchangeSl
}
